import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Link } from 'react-router-dom';

const NavButton = props => (
    <Box>
        <Button hoverIndicator='light-1'>
            <Link to={`/${props.pageLink}`} style={{ textDecoration: 'none' }}>
                <Box pad='medium' direction='row' align='center' gap='small'>
                    {/*<Add />*/}
                    <Text color={'black'}>{props.pageName}</Text>
                </Box>
            </Link>
        </Button>
    </Box>
);

export default NavButton;
