import React, { Component } from 'react';

class SlideOutControls extends Component {
    state = {
        openMenu: false,
        openForm: false,
        formSubmitted: false,
    };

    chooseMenu = e => {
        if (e === 'form') {
            this.setState({ openForm: !this.state.openForm });
            this.setState({ openMenu: false });
        } else if (e === 'menu') {
            this.setState({ openMenu: !this.state.openMenu });
            this.setState({ openForm: false });
        } else {
            this.setState({ openMenu: false });
            this.setState({ openForm: false });
        }
    };

    closeAllSlides = () => {
        this.setState({ openMenu: false });
        this.setState({ openForm: false });
    };

    render() {
        return (
            <div>
                {this.props.children({
                    openMenu: this.state.openMenu,
                    openForm: this.state.openForm,
                    closeAllSlides: this.closeAllSlides,
                    chooseMenu: this.chooseMenu,
                })}
            </div>
        );
    }
}

export default SlideOutControls;
