import React from 'react';
import {
    Box,
    Button,
    Grommet,
    Heading,
    ResponsiveContext,
    Text,
} from 'grommet';
import '../App.css';
import theme from '../Components/theme';
import { Menu } from 'grommet-icons/es6';
import FormSlideOut from '../Components/FormSlideOut';
import MenuSlideOut from '../Components/MenuSlideOut';
import SlideOutControls from '../Components/SlideOutControls';

const Services = () => {
    return (
        <SlideOutControls>
            {controls => (
                <Grommet full theme={theme}>
                    <ResponsiveContext.Consumer>
                        {size => {
                            const isMobile = size === 'small';
                            return (
                                <Box fill>
                                    <Box flex direction='row'>
                                        <Box flex>
                                            <div className={'Services'}>
                                                <Box
                                                    direction='row'
                                                    justify={'end'}
                                                >
                                                    <Button
                                                        icon={
                                                            <Menu
                                                                color={'black'}
                                                                size={'large'}
                                                                label={'menu'}
                                                                onClick={() =>
                                                                    controls.chooseMenu(
                                                                        'menu'
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <Box
                                                        pad={'small'}
                                                        justify={'center'}
                                                    >
                                                        <Button
                                                            primary
                                                            color={'black'}
                                                            label={'REQUEST'}
                                                            onClick={() =>
                                                                controls.chooseMenu(
                                                                    'form'
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>

                                                <Box
                                                    height={'auto'}
                                                    direction={'column'}
                                                    align='center'
                                                >
                                                    <Box
                                                        height={'auto'}
                                                        justify={
                                                            size === 'small'
                                                                ? 'center'
                                                                : 'end'
                                                        }
                                                    >
                                                        <Box
                                                            pad={'medium'}
                                                            fill={isMobile}
                                                            height={'medium'}
                                                            justify={'between'}
                                                        >
                                                            <Box
                                                                align={'center'}
                                                            >
                                                                <Heading margin='small'>
                                                                    {' '}
                                                                    Services{' '}
                                                                </Heading>
                                                            </Box>
                                                            <Box
                                                                direction={
                                                                    size ===
                                                                    'small'
                                                                        ? 'column'
                                                                        : 'row'
                                                                }
                                                                justify={
                                                                    'around'
                                                                }
                                                                align={
                                                                    size ===
                                                                    'small'
                                                                        ? 'center'
                                                                        : 'baseline'
                                                                }
                                                                pad={
                                                                    size ===
                                                                    'small'
                                                                        ? 'large'
                                                                        : 'none'
                                                                }
                                                            >
                                                                <Box
                                                                    background={{
                                                                        color:
                                                                            'light-3',
                                                                        opacity: true,
                                                                    }}
                                                                    round
                                                                    pad={
                                                                        'small'
                                                                    }
                                                                    margin='small'
                                                                    width={
                                                                        'medium'
                                                                    }
                                                                >
                                                                    <Heading
                                                                        level='3'
                                                                        margin='xsmall'
                                                                    >
                                                                        Web App
                                                                        Development
                                                                    </Heading>
                                                                    <Text>
                                                                        Design,
                                                                        Develop
                                                                        and
                                                                        Deploy
                                                                        enterprise
                                                                        applications
                                                                        that
                                                                        enable
                                                                        process
                                                                        and
                                                                        performance
                                                                        improvement
                                                                    </Text>
                                                                </Box>

                                                                <Box
                                                                    background={{
                                                                        color:
                                                                            'light-3',
                                                                        opacity: true,
                                                                    }}
                                                                    round
                                                                    pad={
                                                                        'small'
                                                                    }
                                                                    margin='small'
                                                                    width={
                                                                        'medium'
                                                                    }
                                                                >
                                                                    <Heading
                                                                        level='3'
                                                                        margin='xsmall'
                                                                    >
                                                                        Mobile
                                                                        App
                                                                        Development
                                                                    </Heading>
                                                                    <Text>
                                                                        On the
                                                                        go
                                                                        decisions
                                                                        and
                                                                        demands
                                                                        can be
                                                                        easily
                                                                        translated
                                                                        into
                                                                        significant
                                                                        gains by
                                                                        going
                                                                        mobile
                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </Box>
                                        <FormSlideOut
                                            openForm={controls.openForm}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                        <MenuSlideOut
                                            openMenu={controls.openMenu}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                    </Box>
                                </Box>
                            );
                        }}
                    </ResponsiveContext.Consumer>
                </Grommet>
            )}
        </SlideOutControls>
    );
};

export default Services;
