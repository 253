import React from 'react';
import {
    Box,
    Button,
    Grommet,
    Heading,
    ResponsiveContext,
    Text,
} from 'grommet';
import '../App.css';
import theme from '../Components/theme';
import SlideOutControls from '../Components/SlideOutControls';
import { Menu } from 'grommet-icons/es6';
import FormSlideOut from '../Components/FormSlideOut';
import MenuSlideOut from '../Components/MenuSlideOut';

const Contact = () => {
    return (
        <SlideOutControls>
            {controls => (
                <Grommet full theme={theme}>
                    <ResponsiveContext.Consumer>
                        {size => {
                            const isMobile = size === 'small';
                            return (
                                <Box fill>
                                    <Box flex direction='row'>
                                        <Box flex>
                                            <div className={'Contact'}>
                                                <Box
                                                    direction='row'
                                                    justify={'end'}
                                                >
                                                    <Button
                                                        icon={
                                                            <Menu
                                                                color={'black'}
                                                                size={'large'}
                                                                label={'menu'}
                                                                onClick={() =>
                                                                    controls.chooseMenu(
                                                                        'menu'
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <Box
                                                        pad={'small'}
                                                        justify={'center'}
                                                    >
                                                        <Button
                                                            primary
                                                            color={'black'}
                                                            label={'REQUEST'}
                                                            onClick={() =>
                                                                controls.chooseMenu(
                                                                    'form'
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>

                                                <Box
                                                    height={'auto'}
                                                    direction={'column'}
                                                    align='center'
                                                >
                                                    <Box
                                                        height={'auto'}
                                                        justify={
                                                            size === 'small'
                                                                ? 'center'
                                                                : 'end'
                                                        }
                                                    >
                                                        <Box
                                                            pad={'medium'}
                                                            fill={isMobile}
                                                            align={'end'}
                                                        >
                                                            <Box
                                                                align='center'
                                                                alignSelf='center'
                                                                width='large'
                                                            >
                                                                <Heading>
                                                                    {' '}
                                                                    Contact us{' '}
                                                                </Heading>
                                                                <Box
                                                                    background={{
                                                                        color:
                                                                            'light-3',
                                                                        opacity: true,
                                                                    }}
                                                                    round
                                                                    pad={
                                                                        'small'
                                                                    }
                                                                    justify={
                                                                        'center'
                                                                    }
                                                                >
                                                                    <Box
                                                                        pad={{
                                                                            bottom:
                                                                                'medium',
                                                                        }}
                                                                    >
                                                                        <Text size='large'>
                                                                            info@rapidjaguar.com
                                                                        </Text>
                                                                    </Box>
                                                                    <Text
                                                                        size='large'
                                                                        textAlign={
                                                                            'center'
                                                                        }
                                                                    >
                                                                        919
                                                                        Garnet
                                                                        Ave, STE
                                                                        215{' '}
                                                                        <br />{' '}
                                                                        San
                                                                        Diego,
                                                                        CA 92109
                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </Box>
                                        <FormSlideOut
                                            openForm={controls.openForm}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                        <MenuSlideOut
                                            openMenu={controls.openMenu}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                    </Box>
                                </Box>
                            );
                        }}
                    </ResponsiveContext.Consumer>
                </Grommet>
            )}
        </SlideOutControls>
    );
};

export default Contact;
