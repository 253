import { Route, Switch } from 'react-router-dom';
import './App.css';
import React from 'react';
import HomePage from './Pages/HomePage';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Services from './Pages/Services';
import Journey from './Pages/Journey';

export default function App() {
    return (
        <Switch>
            <Route exact path='/' component={HomePage} />
            <Route exact path='/About' component={About} />
            <Route exact path='/Contact' component={Contact} />
            <Route exact path='/Services' component={Services} />
            <Route exact path='/Journey' component={Journey} />
        </Switch>
    );
}
