const theme = {
    global: {
        font: {
            family: 'Montserrat',
            size: '14px',
            height: '20px',
        },
        text: {
            font: {
                family: 'Roboto Slab',
            },
        },
    },
};

export default theme;
