import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Grommet,
    Box,
    Button,
    Text,
    Image,
    Heading,
    ResponsiveContext,
} from 'grommet';
import { Menu } from 'grommet-icons';
import FormSlideOut from '../Components/FormSlideOut';
import SlideOutControls from '../Components/SlideOutControls';
import theme from '../Components/theme';
import MenuSlideOut from '../Components/MenuSlideOut';
import logo from '../images/Logos/Blacklogo-nobackground.png';
import '../App.css';

export default class HomePage extends Component {
    render() {
        return (
            <SlideOutControls>
                {controls => (
                    <Grommet full theme={theme}>
                        <ResponsiveContext.Consumer>
                            {size => {
                                return (
                                    <Box fill>
                                        <Box flex direction='row'>
                                            <Box flex>
                                                <div className={'Home'}>
                                                    <Box
                                                        height={'25%'}
                                                        direction={'column'}
                                                        justify={'between'}
                                                        pad={'medium'}
                                                    >
                                                        <Box
                                                            height={'30%'}
                                                            gap={'medium'}
                                                            direction={'row'}
                                                            alignSelf={'end'}
                                                            justify={
                                                                size === 'small'
                                                                    ? 'between'
                                                                    : 'end'
                                                            }
                                                            fill={
                                                                size === 'small'
                                                            }
                                                        >
                                                            <Box
                                                                height={'100%'}
                                                                justify={
                                                                    'center'
                                                                }
                                                            >
                                                                <Button
                                                                    icon={
                                                                        <Menu
                                                                            color={
                                                                                'black'
                                                                            }
                                                                            size={
                                                                                'large'
                                                                            }
                                                                            label={
                                                                                'menu'
                                                                            }
                                                                            onClick={() =>
                                                                                controls.chooseMenu(
                                                                                    'menu'
                                                                                )
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            </Box>

                                                            <Box
                                                                height={'100%'}
                                                                justify={
                                                                    'center'
                                                                }
                                                            >
                                                                <Button
                                                                    primary
                                                                    color={
                                                                        'black'
                                                                    }
                                                                    label={
                                                                        'REQUEST'
                                                                    }
                                                                    onClick={() =>
                                                                        controls.chooseMenu(
                                                                            'form'
                                                                        )
                                                                    }
                                                                />
                                                            </Box>
                                                        </Box>

                                                        <Box
                                                            height={'175px'}
                                                            width={'25%'}
                                                            justify={'start'}
                                                            margin={
                                                                size === 'small'
                                                                    ? {
                                                                          bottom:
                                                                              '0px',
                                                                      }
                                                                    : {
                                                                          bottom:
                                                                              '10px',
                                                                      }
                                                            }
                                                            fill={
                                                                size === 'small'
                                                            }
                                                        >
                                                            <Image
                                                                fit='contain'
                                                                src={logo}
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box
                                                        height={'75%'}
                                                        direction={'column'}
                                                        pad={'medium'}
                                                    >
                                                        <Box
                                                            height={'70%'}
                                                            justify={
                                                                size === 'small'
                                                                    ? 'center'
                                                                    : 'end'
                                                            }
                                                        >
                                                            <Box
                                                                margin={
                                                                    size ===
                                                                    'small'
                                                                        ? {
                                                                              left:
                                                                                  '0',
                                                                          }
                                                                        : {
                                                                              left:
                                                                                  '5%',
                                                                          }
                                                                }
                                                            >
                                                                <Heading
                                                                    size={
                                                                        'medium'
                                                                    }
                                                                    textAlign={
                                                                        'center'
                                                                    }
                                                                    alignSelf={
                                                                        size ===
                                                                        'small'
                                                                            ? 'center'
                                                                            : 'start'
                                                                    }
                                                                >
                                                                    Enterprise
                                                                    Applications
                                                                    designed.{' '}
                                                                    <br />{' '}
                                                                    Results
                                                                    achieved.
                                                                </Heading>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            height={'auto'}
                                                            align='end'
                                                            pad={{
                                                                horizontal:
                                                                    'large',
                                                            }}
                                                        >
                                                            <Box
                                                                direction='row'
                                                                width='large'
                                                                overflow={
                                                                    'auto'
                                                                }
                                                                justify={
                                                                    size ===
                                                                    'small'
                                                                        ? 'center'
                                                                        : 'start'
                                                                }
                                                            >
                                                                {size !==
                                                                    'small' && (
                                                                    <Text
                                                                        textAlign={
                                                                            'start'
                                                                        }
                                                                        alignSelf={
                                                                            'end'
                                                                        }
                                                                        size={
                                                                            'large'
                                                                        }
                                                                        id={
                                                                            'smallVertical'
                                                                        }
                                                                    >
                                                                        Rapid
                                                                        Jaguar
                                                                        is an
                                                                        app
                                                                        development
                                                                        firm
                                                                        that
                                                                        specializes
                                                                        in web
                                                                        and
                                                                        mobile
                                                                        apps. We
                                                                        work
                                                                        with our
                                                                        clients
                                                                        build
                                                                        compelling
                                                                        apps
                                                                        that
                                                                        provide
                                                                        lasting
                                                                        excitement
                                                                        and
                                                                        value.
                                                                    </Text>
                                                                )}
                                                                <Link
                                                                    to='/services'
                                                                    style={{
                                                                        alignSelf:
                                                                            'flex-end',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        primary
                                                                        alignSelf={
                                                                            'end'
                                                                        }
                                                                        color={
                                                                            'black'
                                                                        }
                                                                        label={
                                                                            'Services'
                                                                        }
                                                                    />
                                                                </Link>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Box>
                                            <FormSlideOut
                                                openForm={controls.openForm}
                                                closeAllSlides={
                                                    controls.closeAllSlides
                                                }
                                            />
                                            <MenuSlideOut
                                                openMenu={controls.openMenu}
                                                closeAllSlides={
                                                    controls.closeAllSlides
                                                }
                                            />
                                        </Box>
                                    </Box>
                                );
                            }}
                        </ResponsiveContext.Consumer>
                    </Grommet>
                )}
            </SlideOutControls>
        );
    }
}
