import React from 'react';
import {
    Box,
    Button,
    Diagram,
    Grommet,
    Heading,
    ResponsiveContext,
    Stack,
    Text,
} from 'grommet';
import '../App.css';
import design from '../images/Journey/designImage.jpg';
import dev from '../images/Journey/devImage.jpg';
import testing from '../images/Journey/testingImage.jpg';
import deploy from '../images/Journey/deployImage.jpg';
import theme from '../Components/theme';
import { Menu } from 'grommet-icons/es6';
import FormSlideOut from '../Components/FormSlideOut';
import MenuSlideOut from '../Components/MenuSlideOut';
import SlideOutControls from '../Components/SlideOutControls';

const Journey = () => {
    return (
        <SlideOutControls>
            {controls => (
                <Grommet full theme={theme}>
                    <ResponsiveContext.Consumer>
                        {size => {
                            const isMobile = size === 'small';
                            return (
                                <Box fill>
                                    <Box flex direction='row'>
                                        <Box flex>
                                            <div className={'Journey'}>
                                                <Box
                                                    direction='row'
                                                    justify={'end'}
                                                >
                                                    <Button
                                                        icon={
                                                            <Menu
                                                                color={'black'}
                                                                size={'large'}
                                                                label={'menu'}
                                                                onClick={() =>
                                                                    controls.chooseMenu(
                                                                        'menu'
                                                                    )
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <Box
                                                        pad={'small'}
                                                        justify={'center'}
                                                    >
                                                        <Button
                                                            primary
                                                            color={'black'}
                                                            label={'REQUEST'}
                                                            onClick={() =>
                                                                controls.chooseMenu(
                                                                    'form'
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Box>

                                                <Box
                                                    height={'auto'}
                                                    direction={'column'}
                                                    align='end'
                                                >
                                                    <Box
                                                        height={'auto'}
                                                        justify={
                                                            size === 'small'
                                                                ? 'center'
                                                                : 'end'
                                                        }
                                                    >
                                                        <Box
                                                            pad={'medium'}
                                                            fill={isMobile}
                                                            align={'end'}
                                                        >
                                                            <Box>
                                                                <Box
                                                                    width='large'
                                                                    height='100vh'
                                                                >
                                                                    <Heading
                                                                        margin='small'
                                                                        textAlign={
                                                                            'center'
                                                                        }
                                                                    >
                                                                        {' '}
                                                                        Journey{' '}
                                                                    </Heading>
                                                                    <Stack
                                                                        guidingChild={
                                                                            1
                                                                        }
                                                                        fill
                                                                    >
                                                                        <Diagram
                                                                            connections={[
                                                                                {
                                                                                    fromTarget:
                                                                                        '1',
                                                                                    toTarget:
                                                                                        '2',
                                                                                    thickness:
                                                                                        'xsmall',
                                                                                    color:
                                                                                        'dark-1',
                                                                                    type:
                                                                                        'rectilinear',
                                                                                },
                                                                                {
                                                                                    fromTarget:
                                                                                        '2',
                                                                                    toTarget:
                                                                                        '3',
                                                                                    thickness:
                                                                                        'xsmall',
                                                                                    color:
                                                                                        'dark-1',
                                                                                    type:
                                                                                        'rectilinear',
                                                                                },
                                                                                {
                                                                                    fromTarget:
                                                                                        '3',
                                                                                    toTarget:
                                                                                        '4',
                                                                                    thickness:
                                                                                        'xsmall',
                                                                                    color:
                                                                                        'dark-1',
                                                                                    type:
                                                                                        'rectilinear',
                                                                                },
                                                                            ]}
                                                                        />
                                                                        <Box
                                                                            fill='horizontal'
                                                                            pad={{
                                                                                horizontal:
                                                                                    'medium',
                                                                            }}
                                                                            align={
                                                                                size ===
                                                                                'small'
                                                                                    ? 'center'
                                                                                    : 'baseline'
                                                                            }
                                                                            justify={
                                                                                'evenly'
                                                                            }
                                                                            height={
                                                                                size ===
                                                                                'small'
                                                                                    ? '100%'
                                                                                    : ''
                                                                            }
                                                                        >
                                                                            <Box
                                                                                direction='row'
                                                                                fill='horizontal'
                                                                                justify='between'
                                                                                margin='small'
                                                                                align='center'
                                                                            >
                                                                                <Box
                                                                                    id='1'
                                                                                    margin='xxsmall'
                                                                                    pad='small'
                                                                                    background='#ACACAC'
                                                                                    width='medium'
                                                                                    round
                                                                                >
                                                                                    <Heading
                                                                                        level={
                                                                                            3
                                                                                        }
                                                                                        margin='xsmall'
                                                                                    >
                                                                                        Design
                                                                                    </Heading>
                                                                                    <Text size='medium'>
                                                                                        Where
                                                                                        the
                                                                                        fun
                                                                                        begins.
                                                                                        The
                                                                                        back
                                                                                        end
                                                                                        processes
                                                                                        and
                                                                                        UI/UX
                                                                                        design
                                                                                        come
                                                                                        together
                                                                                        to
                                                                                        ensure
                                                                                        that
                                                                                        the
                                                                                        apps
                                                                                        goals
                                                                                        are
                                                                                        achieved
                                                                                    </Text>
                                                                                </Box>
                                                                                {size !==
                                                                                    'small' && (
                                                                                    <Box
                                                                                        height='xsmall'
                                                                                        width='small'
                                                                                        round
                                                                                        background={{
                                                                                            image: `url(${design})`,
                                                                                        }}
                                                                                        margin={{
                                                                                            right:
                                                                                                'medium',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                            <Box
                                                                                direction='row'
                                                                                fill='horizontal'
                                                                                justify='between'
                                                                                margin='small'
                                                                                align='center'
                                                                            >
                                                                                {size !==
                                                                                    'small' && (
                                                                                    <Box
                                                                                        height='xsmall'
                                                                                        width='small'
                                                                                        round
                                                                                        background={{
                                                                                            image: `url(${dev})`,
                                                                                        }}
                                                                                        margin={{
                                                                                            left:
                                                                                                'medium',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <Box
                                                                                    id='2'
                                                                                    margin='xxsmall'
                                                                                    pad='small'
                                                                                    background='#ACACAC'
                                                                                    width='medium'
                                                                                    round
                                                                                >
                                                                                    <Heading
                                                                                        level={
                                                                                            3
                                                                                        }
                                                                                        margin='xsmall'
                                                                                    >
                                                                                        Development
                                                                                    </Heading>
                                                                                    <Text>
                                                                                        Your
                                                                                        idea
                                                                                        is
                                                                                        now
                                                                                        becoming
                                                                                        a
                                                                                        reality
                                                                                        as
                                                                                        our
                                                                                        devlopment
                                                                                        team
                                                                                        works
                                                                                        with
                                                                                        you
                                                                                        to
                                                                                        achieve
                                                                                        the
                                                                                        perfect
                                                                                        app
                                                                                    </Text>
                                                                                </Box>
                                                                            </Box>

                                                                            <Box
                                                                                direction='row'
                                                                                fill='horizontal'
                                                                                justify='between'
                                                                                margin='small'
                                                                                align='center'
                                                                            >
                                                                                <Box
                                                                                    id='3'
                                                                                    margin='xxsmall'
                                                                                    pad='small'
                                                                                    background='#ACACAC'
                                                                                    width='medium'
                                                                                    round
                                                                                >
                                                                                    <Heading
                                                                                        level={
                                                                                            3
                                                                                        }
                                                                                        margin='xsmall'
                                                                                    >
                                                                                        Testing
                                                                                    </Heading>
                                                                                    <Text>
                                                                                        A
                                                                                        thorough
                                                                                        run
                                                                                        through
                                                                                        of
                                                                                        the
                                                                                        app
                                                                                        and
                                                                                        its
                                                                                        features,
                                                                                        functions
                                                                                        and
                                                                                        capabilities
                                                                                        to
                                                                                        ensure
                                                                                        everything
                                                                                        is
                                                                                        working
                                                                                        the
                                                                                        way
                                                                                        you
                                                                                        want
                                                                                        it.
                                                                                    </Text>
                                                                                </Box>
                                                                                {size !==
                                                                                    'small' && (
                                                                                    <Box
                                                                                        height='xsmall'
                                                                                        width='small'
                                                                                        round
                                                                                        background={{
                                                                                            image: `url(${testing})`,
                                                                                        }}
                                                                                        margin={{
                                                                                            right:
                                                                                                'medium',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                            <Box
                                                                                direction='row'
                                                                                fill='horizontal'
                                                                                justify='between'
                                                                                margin='small'
                                                                                align='center'
                                                                            >
                                                                                {size !==
                                                                                    'small' && (
                                                                                    <Box
                                                                                        height='xsmall'
                                                                                        width='small'
                                                                                        round
                                                                                        background={{
                                                                                            image: `url(${deploy})`,
                                                                                        }}
                                                                                        margin={{
                                                                                            left:
                                                                                                'medium',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                                <Box
                                                                                    id='4'
                                                                                    margin='xxsmall'
                                                                                    pad='small'
                                                                                    background='#ACACAC'
                                                                                    width='medium'
                                                                                    round
                                                                                >
                                                                                    <Heading
                                                                                        level={
                                                                                            3
                                                                                        }
                                                                                        margin='xsmall'
                                                                                    >
                                                                                        Deployment
                                                                                    </Heading>
                                                                                    <Text>
                                                                                        Uncork
                                                                                        the
                                                                                        champagne,
                                                                                        the
                                                                                        app
                                                                                        is
                                                                                        ready!
                                                                                    </Text>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </div>
                                        </Box>
                                        <FormSlideOut
                                            openForm={controls.openForm}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                        <MenuSlideOut
                                            openMenu={controls.openMenu}
                                            closeAllSlides={
                                                controls.closeAllSlides
                                            }
                                        />
                                    </Box>
                                </Box>
                            );
                        }}
                    </ResponsiveContext.Consumer>
                </Grommet>
            )}
        </SlideOutControls>
    );
};

export default Journey;
