import React from 'react';
import { Box, Collapsible, Image } from 'grommet';
import { FormClose } from 'grommet-icons';
import slideOutLogo from '../images/Logos/Whitelogo-nobackground.png';
import NavButton from './NavButton';

const MenuSlideOut = ({ openMenu, closeAllSlides, homePage }) => {
    const pages = homePage
        ? [
              { title: 'About', link: 'About' },
              { title: 'Contact Us', link: 'Contact' },
              { title: 'Services', link: 'Services' },
              { title: 'Journey', link: 'Journey' },
          ]
        : [
              { title: 'Home', link: '' },
              { title: 'About', link: 'About' },
              { title: 'Contact Us', link: 'Contact' },
              { title: 'Services', link: 'Services' },
              { title: 'Journey', link: 'Journey' },
          ];
    return (
        <Collapsible direction='horizontal' open={openMenu}>
            <Box
                flex
                width='medium'
                background='#ACACAC'
                pad='small'
                elevation='small'
            >
                <Box width='medium' align={'end'}>
                    <FormClose onClick={closeAllSlides} />
                </Box>
                <Box height={'15%'}>
                    <Image src={slideOutLogo} fit='contain' />
                </Box>
                <Box fill>
                    {pages.map(page => {
                        return (
                            <NavButton
                                key={page.title}
                                pageName={page.title}
                                pageLink={page.link}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Collapsible>
    );
};

export default MenuSlideOut;
