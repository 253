import React from 'react';

export const ColoredLine = ({ color, width }) => (
    <hr
        style={{
            borderStyle: 'none',
            borderWidth: '0',
            margin: '34px 0',
            color: 'rgba(0, 0, 0, 0.76)',
            backgroundColor: 'rgba(0,0,0,.15)',
            height: '2px',
        }}
    />
);
