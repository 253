import React, { useState } from 'react';
import * as emailjs from 'emailjs-com';
import { Box, Button, Form, FormField, TextArea } from 'grommet';
import { Like } from 'grommet-icons';

const ContactForm = ({ close }) => {
    const [state, setState] = useState({
        isFormDisabled: false,
        isFormSubmitted: false,
    });
    const handleSubmit = event => {
        event.preventDefault();
        setState({ isFormDisabled: false });

        const templateVariables = {
            name: event.target[0].value,
            email: event.target[1].value,
            phone: event.target[2].value,
            comment: event.target[3].value,
        };

        const nameOfEmailService = 'gmail';
        const userId = 'user_6GNJLtZ7nHCNADL2Wb3xm';
        const emailTemplateId = 'template_W4HeN5MQ';

        emailjs
            .send(
                nameOfEmailService,
                emailTemplateId,
                templateVariables,
                userId
            )
            .then(
                response => {
                    setState({ isFormDisabled: false });
                    event.target.reset();
                    setState({ isFormSubmitted: false });
                },
                err => {
                    console.log('FAILED...', err);
                }
            );
    };

    const handleReset = event => {
        document.getElementById('contactForm').reset();
    };

    return state.isFormSubmitted ? (
        <Box fill align={'center'} justify={'center'}>
            <Box>
                <Like size='xlarge' />
            </Box>
            <Box pad={'medium'}>Thanks for your submission</Box>
        </Box>
    ) : (
        <Box fill align='center' justify='center' pad={{ top: '10%' }}>
            <Box fill>
                <Form
                    id={'contactForm'}
                    onReset={e => handleReset(e)}
                    onSubmit={e => handleSubmit(e)}
                    disabled={true}
                >
                    <FormField
                        label='Name'
                        name='name'
                        required
                        validate={{ regexp: /^[a-z]/i }}
                    />
                    <FormField
                        label='Email'
                        name='email'
                        type='email'
                        required
                    />
                    <FormField
                        label='Phone Number'
                        name='PhoneNumber'
                        required
                        validate={{
                            regexp: /^[0-9]{7,12}$/,
                            message: '7-12 digits',
                        }}
                    />
                    <FormField
                        resize={false}
                        fill
                        label='Comments'
                        name='comments'
                        component={TextArea}
                    />
                    <Box
                        direction='row'
                        justify='between'
                        margin={{ top: 'medium' }}
                    >
                        <Button
                            disabled={state.isFormDisabled}
                            label='Cancel'
                            onClick={() => close()}
                        />
                        <Button
                            disabled={state.isFormDisabled}
                            type='reset'
                            label='Reset'
                        />
                        <Button
                            disabled={state.isFormDisabled}
                            type='submit'
                            label='Submit'
                            primary
                        />
                    </Box>
                </Form>
            </Box>
        </Box>
    );
};

export default ContactForm;
