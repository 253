import React from 'react';
import { Box, Collapsible, Image } from 'grommet';
import { FormClose } from 'grommet-icons';
import slideOutLogo from '../images/Logos/Whitelogo-nobackground.png';
import ContactForm from './ContactForm';

const FormSlideOut = ({ openForm, closeAllSlides }) => {
    return (
        <Collapsible direction='horizontal' open={openForm}>
            <Box
                flex
                width='medium'
                background='#ACACAC'
                pad='small'
                elevation='small'
            >
                <Box width='medium' align={'end'}>
                    <FormClose onClick={() => closeAllSlides()} />
                </Box>
                <Box height={'15%'}>
                    <Image src={slideOutLogo} fit='contain' />
                </Box>
                <ContactForm close={() => closeAllSlides()} />
            </Box>
        </Collapsible>
    );
};

export default FormSlideOut;
