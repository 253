import React, { Component } from 'react';
import {
    Anchor,
    Box,
    Button,
    Grommet,
    Heading,
    Image,
    ResponsiveContext,
    Text,
} from 'grommet';
import '../App.css';
import theme from '../Components/theme';
import { Menu } from 'grommet-icons/es6';
import FormSlideOut from '../Components/FormSlideOut';
import MenuSlideOut from '../Components/MenuSlideOut';
import SlideOutControls from '../Components/SlideOutControls';
import jaguarImage from '../images/About/jaguar-cub-e2.jpg';
import { ColoredLine } from '../Components/ColoredLine';
import logo from '../images/Logos/Blacklogo-nobackground.png';
import { Link } from 'react-router-dom';

export default class About extends Component {
    render() {
        return (
            <SlideOutControls>
                {controls => (
                    <Grommet full theme={theme}>
                        <ResponsiveContext.Consumer>
                            {size => {
                                const isMobile = size === 'small';
                                return (
                                    <Box fill>
                                        <Box flex direction='row'>
                                            <Box flex>
                                                <div className={'About'}>
                                                    <Box
                                                        direction='row'
                                                        justify={'end'}
                                                    >
                                                        <Button
                                                            icon={
                                                                <Menu
                                                                    color={
                                                                        'black'
                                                                    }
                                                                    size={
                                                                        'large'
                                                                    }
                                                                    label={
                                                                        'menu'
                                                                    }
                                                                    onClick={() =>
                                                                        controls.chooseMenu(
                                                                            'menu'
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <Box
                                                            pad={'small'}
                                                            justify={'center'}
                                                        >
                                                            <Button
                                                                primary
                                                                color={'black'}
                                                                label={
                                                                    'REQUEST'
                                                                }
                                                                onClick={() =>
                                                                    controls.chooseMenu(
                                                                        'form'
                                                                    )
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>

                                                    <Box
                                                        height={'auto'}
                                                        justify={'center'}
                                                    >
                                                        <Box
                                                            pad={'medium'}
                                                            fill={isMobile}
                                                            align={'center'}
                                                        >
                                                            <Box
                                                                direction={
                                                                    'column'
                                                                }
                                                                width={
                                                                    size ===
                                                                    'small'
                                                                        ? '100%'
                                                                        : '50%'
                                                                }
                                                            >
                                                                <Heading
                                                                    alignSelf={
                                                                        'center'
                                                                    }
                                                                >
                                                                    {' '}
                                                                    About us{' '}
                                                                </Heading>
                                                                <Box
                                                                    background={{
                                                                        color:
                                                                            'light-3',
                                                                        opacity: true,
                                                                    }}
                                                                    round
                                                                    pad={
                                                                        'small'
                                                                    }
                                                                    width='large'
                                                                >
                                                                    <Text
                                                                        size='medium'
                                                                        color={
                                                                            'black'
                                                                        }
                                                                    >
                                                                        At
                                                                        Jaguar,
                                                                        we
                                                                        provide
                                                                        both
                                                                        design
                                                                        and
                                                                        implementation
                                                                        services
                                                                        for web
                                                                        and
                                                                        mobile
                                                                        apps. We
                                                                        work
                                                                        seamlessly
                                                                        with our
                                                                        clients
                                                                        to build
                                                                        compelling
                                                                        applications
                                                                        that
                                                                        provide
                                                                        lasting
                                                                        excitement
                                                                        and
                                                                        value.
                                                                        Whether
                                                                        internal
                                                                        or
                                                                        customer
                                                                        facing
                                                                        apps, we
                                                                        ensure
                                                                        that our
                                                                        work is
                                                                        solidly
                                                                        designed
                                                                        and
                                                                        loaded
                                                                        with
                                                                        functionality.
                                                                        We take
                                                                        our
                                                                        client’s
                                                                        initial
                                                                        concept
                                                                        and
                                                                        merge it
                                                                        with
                                                                        everything
                                                                        our team
                                                                        knows
                                                                        about
                                                                        enterprise
                                                                        applications
                                                                        and app
                                                                        development.
                                                                        We help
                                                                        you turn
                                                                        ideas
                                                                        into
                                                                        actual
                                                                        applications
                                                                        that
                                                                        bring
                                                                        value to
                                                                        your
                                                                        clients
                                                                        and
                                                                        stakeholders.
                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                width={
                                                                    isMobile
                                                                        ? '100%'
                                                                        : '50%'
                                                                }
                                                                pad={{
                                                                    top:
                                                                        'large',
                                                                    bottom:
                                                                        'large',
                                                                }}
                                                            >
                                                                <ColoredLine
                                                                    width={
                                                                        '100%'
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box direction={isMobile ? 'column':'row'} align={isMobile ? 'center': ''} width={isMobile ? '100%':'50%'}>
                                                                <Anchor href="https://gifts.worldwildlife.org/gift-center/gifts/Species-Adoptions/Jaguar.aspx">
                                                                    <Box height={'medium'} width={'medium'}>
                                                                        <Image
                                                                            fit='contain'
                                                                            src={
                                                                                jaguarImage
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </Anchor>
                                                                <Box  pad={isMobile ? {'top':'20px'} : {}} direction={'column'} justify={'between'} width={isMobile ? '100%':'50%'}>
                                                                    <Text ><i>Adopt a Jaguar Program</i></Text>
                                                                    <ColoredLine width={'100%'}/>
                                                                    <Text size={'medium'}>
                                                                        Each year we contribute a portion of our profits to the <a style={{color:'black', textDecoration: 'none'}} href="https://gifts.worldwildlife.org/gift-center/gifts/Species-Adoptions/Jaguar.aspx" title="Go to WWF Adopt a Jaguar Program">WWF Adopt a Jaguar Program</a>, which allows us to symbolically adopt and support the preservation of the Jaguar.  We are committed to the well-being and support of these amazing North American animals.

                                                                    </Text>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </div>
                                            </Box>
                                            <FormSlideOut
                                                openForm={controls.openForm}
                                                closeAllSlides={
                                                    controls.closeAllSlides
                                                }
                                            />
                                            <MenuSlideOut
                                                openMenu={controls.openMenu}
                                                closeAllSlides={
                                                    controls.closeAllSlides
                                                }
                                            />
                                        </Box>
                                    </Box>
                                );
                            }}
                        </ResponsiveContext.Consumer>
                    </Grommet>
                )}
            </SlideOutControls>
        );
    }
}
